.header_flex {
  display: flex;
  justify-content: space-between;
}
.hh {
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}
.headerButton a {
  font-size: 18px !important;
  padding: 0px 10px;
  font-weight: 500;
  /* border: 2px solid #7e7e7e; */
  display: inline-block;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  color: #0f172a;
}
.headerButton a:hover {
  color: #d6002f;
}
.header {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  padding: 10px 20px;
  /* padding: 1px 60px; */
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}
.dropdown_content {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
}
.dropdown_content li a {
  text-decoration: none;
  color: #000;
  display: inline-block;
  /* padding: 4px 0px; */
  font-family: "Poppins";
  font-size: 10px;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 16px;
  /* margin-bottom: 15px; */
}
.dropdown_menu .dropdown_content ul li a:hover {
  color: #d6002f !important;
}

.dropdown_content li {
  list-style: none;
}

.open {
  max-height: 200px;
}
/* .container {
  padding: 30px 0px 30px 10px;
} */

.hamburger-icon {
  width: 30px;
  height: 3px;
  background-color: #7e7e7e;
  margin: 6px 0;
  transition: transform 0.2s;
}

@media (min-width: 992px) {
  .hamburger_menu {
    display: none;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  /* .lists_Desktop {
    display: block;
  } */
}
/* @media (max-width: 993px) {
  .lists_Desktop {
    display: none;
  }
} */
.lists {
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ul_list {
  display: flex;
  justify-content: space-around;
}
.ul_list li a {
  text-decoration: none;
  color: #7e7e7e;
  font-size: 16px;
  font-weight: 500;
}
.ul_list li {
  list-style: none;

  padding-right: 20px;
}
.contactButton {
  border: 2px solid #7e7e7e;
  border-radius: 6px;
  padding: 2px 10px;
}
@media (min-width: 1200px) {
  .header {
    padding: 10px 50px;
  }
}
.centered-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: var(
    --background-paper
  ); /* Assuming --background-paper is defined */
  /* border: 2px solid #000; */
  /* box-shadow: 0 0 24px rgba(0, 0, 0, 0.24); */
  box-shadow: 0 0 24px rgba(199, 199, 244, 0.3); /* Cool blue color */
  padding: 16px; /* You can use padding instead of p */
  background-color: rgb(246, 243, 243);
  z-index: 1000; /* Adjust this value as needed */
  border-radius: 6px;
  padding-bottom: 25px;
}
.contactTitle {
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
  color: black;
  font-weight: 600;
}
.conatctLists li {
  list-style: none;
  margin-bottom: 10px;
  /* display: flex;
  align-items: center; */
}
.conatctLists li:hover {
  color: #d6002f !important;
}
.conatctLists li a:hover {
  color: #d6002f !important;
}
.conatctLists li a {
  text-decoration: none;
  color: #7e7e7e;
  list-style: none;
  margin-bottom: 10px;
}
.headerButton :hover {
  border-color: #d6002f;
}
@media (max-width: 493px) {
  .centered-element {
    width: 315px;
  }
}
@media (max-width: 992px) {
  .lists_Desktop li {
    display: none;
  }

  /* .lists_Desktop {
    display: block;
  } */
}

/* headers */
.headers {
  background-color: #000;
  padding-top: 20px;
}
.lists {
  display: flex;
  justify-content: space-around;
}
.lists li {
  list-style: none;
  color: white;
}
.lists li a {
  text-decoration: none;
  color: white;
  font-size: 15px;
  padding: 5px;
  /* border: 1px solid transparent;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  ); */
  /* background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  ); */
  /* border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5); */
}

.lists li a:hover {
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  border-radius: 15px;
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
}
.containers {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  /* margin: 0px 20px; */
}
@media (min-width: 541px) {
  /* .containers {
    margin: 0px !important;
  } */
}
@media (min-width: 1000px) {
  /* .lists li a {
    font-size: 18px;
  } */
}
