.list ul {
  display: flex;
  justify-content: space-between;
}
.beny_tm_about {
  background-color: black !important;
}

.mypicImage {
  padding-right: 20px;
}
.mypicImage {
  max-width: 450px;
}
.beny_tm_title_holder {
  margin-top: 50px;
}

/* .mypicImage {
  margin: 0 auto;
} */
.beny_tm_title_holder p {
  margin-bottom: 30px;
}

/* Aboutsss */
.About {
  background-color: black;
}
.hiThere {
  display: none;
}
.desc {
  margin: 40px 0px;
  /* max-width: 80%; */
}
.shorts {
  margin: 40px 70px;
  max-width: 50%;
}
.images {
  width: 100%;
  max-width: 450px;
}
.linear-gradient-color {
  color: rgba(170, 54, 124, 0.5);
}
.year {
  color: rgb(207, 203, 203);
}
.frameworks {
  color: #7e7e7e;
}
.display_none {
  display: none;
}
.mypicImages {
  max-width: 450px;
}
.piclinks_about {
  display: flex;
  /* justify-content: flex-end; */
  margin-top: 30px;
  align-items: center;
  /* padding-right: 40px; */
}
.githubs {
  margin-top: 3px;
}

.phoneNumber a {
  background: linear-gradient(
    90.21deg,
    rgba(194, 14, 122, 0.8) -5.91%,
    rgba(147, 48, 183, 0.8) 111.58%
  ) !important;
  text-decoration: none !important;
}
.phoneNumber a:hover {
  background: linear-gradient(
    90.21deg,
    rgba(194, 14, 122, 0.8) -5.91%,
    rgba(147, 48, 183, 0.8) 111.58%
  ) !important;
  text-decoration: none !important;
}
@media (min-width: 520px) {
  .mypicImage {
    margin-left: 20px;
  }
}
@media (min-width: 768px) {
  /* .mypicImage {
    display: none;
  } */
  .aboutInnerDiv {
    display: flex;
  }
  .flex_continer {
    display: flex;
  }
}

@media (max-width: 800px) {
  /* .list ul {
    display: block;
  } */

  .aboutInnerDiv {
    display: none !important;
  }
}
@media (min-width: 870px) {
  .beny_tm_title_holder {
    /* margin-top: 150px; */
  }
}
@media (min-width: 992px) {
  .pic_desc {
    display: none !important;
  }
  .display_none {
    display: block !important;
    padding-bottom: 10px;
  }
  .eww {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hiThere {
    display: block !important;
    margin-bottom: 20px;
  }
}
@media (min-width: 1041px) {
  .beny_tm_title_holder {
    margin-top: 150px;
  }
}
