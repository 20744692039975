.footer_container {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  align-items: center;
}
.hr_footer {
  border-style: solid;
  border-width: 2px;
  border-image: linear-gradient(
      90.21deg,
      rgba(236, 17, 148, 0.8) -5.91%,
      rgba(208, 126, 238, 0.8) 111.58%
    )
    1;
}

@media (min-width: 1041px) {
  .hr_footer {
    margin-top: 60px;
  }
}

.eww_container {
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );

  padding-left: 5px;
  width: 24px; /* Adjust the size as needed */
  height: 24px; /* Adjust the size as needed */
  border-radius: 50%;
}
.icons {
  color: white;
}
