.banner {
  background-image: url("../../sources/img/slider/6.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 766px;
}
.bannerImage {
  background-image: url("../../sources/img/slider/6.jpg");
  height: 850px; /* Remove the quotes around 800px */
  width: 100%;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.name {
  opacity: 1;
  visibility: visible;
  top: 0px;
  font-size: 23px;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 26px;
  color: #0f172a;
  transition: all 0.8s ease;
  line-height: 1.3;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.job {
  color: #d6002f;

  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.beny_tm_button {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  opacity: 1;
  visibility: visible;
  top: 0px;
  position: relative;
  transition-delay: 0.9s;
}
.beny_tm_button a {
  font-size: 16px;
  padding: 2px 15px;
  font-weight: 500;
  border: 2px solid #7e7e7e;
  display: inline-block;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}
.beny_tm_button a:hover {
  border-color: #d6002f;
}

.content {
  width: 100%;
  max-width: 400px;
  min-height: 70vh;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 10px;
}

.overlays {
  margin-top: 250px;
  margin-left: 20px;
}

.banner_title {
  margin: 0; /* Remove default margin for h3 elements */
}

.p_banner {
  margin-top: 10px; /* Adjust the margin as needed */
}
.bannerss {
  background-image: url("../../sources/img/slider/21.jpg");
  width: 100%;
  height: 685px;
  background-repeat: no-repeat;
  background-size: cover; /* You can adjust this based on your preference */
  background-position: center;
  /* display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px; */
  padding-top: 25px;
}
.green {
  color: #289061 !important;
}

.banner_title {
  text-align: center !important;
  margin: 0 auto !important;
  margin: 0 !important;
  color: white !important;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.secondBannerSection {
  background-color: rgb(164, 163, 163);
  /* padding: 5px; */
  /* opacity: 0.5; */
}
.Bannerbuttons a {
  font-size: 16px;
  padding: 2px 15px;
  font-weight: 500;
  /* border: 2px solid #7e7e7e; */
  display: inline-block;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  color: #0f172a;
  /* padding-top: 5px; */
  margin-right: 10px;
  /* margin-top: 15px; */
}
.Bannerbuttons {
  /* display: flex; */
  /* justify-content: end; */
  /* align-items: center; */
  padding: 5px !important;
}

.banner_div {
  padding-top: 180px;
}
.containers {
  max-width: 500px;
  /* padding-top: 10px; */
  margin: 0 auto;
}
.title_banner {
  font-size: 2.8rem;
}
.p_banner {
  text-align: center;
  max-width: 500px;
  align-items: center;
  margin: 0 auto;
  padding: 5px;
  color: rgb(155, 153, 153) !important;
}

.name {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  /* margin-bottom: 16px; */
  /* display: inline-block; */
  max-width: 200px;
  margin: 0 auto !important;
  align-items: center;
}
.header_div {
  margin: 0px 20px;
}

.banner_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  text-align: center;
}
.header_divs {
  position: fixed;
  top: 20px;
  right: 15px;
  left: 15px;
  z-index: 1030;
}
.scrolled {
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
}

@media (min-width: 625px) {
  .banner_div {
    padding-top: 175px;
  }
}
@media (min-width: 1000px) {
  .title_banner {
    font-size: 3.5rem;
  }
}
@media (min-width: 1200px) {
  .overlays {
    margin-left: 100px;
  }
  .bannerss {
    height: 800px !important; /* Remove the quotes around 800px */
  }
  .banner_div {
    height: 65vh;
  }
}
@media (min-width: 1040px) {
  .job {
    font-size: 55px;
    width: 50%;
  }
}
/* @media (max-width: 1040px) {
  .job {
    font-size: 55px;
    width: 50%;
  }
} */
@media (max-width: 1600px) {
  .content {
    max-width: 600px;
    transition: max-width 0.3s ease-in-out, margin-left 0.3s ease-in-out;
  }
}
@media (max-width: 699px) {
  .job {
    font-size: 35px !important;
    margin-bottom: 10px !important;
    transition: font-size 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;
  }
}
@media (min-width: 700px) {
  .content {
    max-width: 600px;
    transition: max-width 0.3s ease-in-out, margin-left 0.3s ease-in-out;
  }
  .job {
    color: #d6002f;
    font-size: 40px;
    font-weight: bold;
    transition: font-size 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;
  }
}
@media (min-width: 1000px) {
  .content {
    margin-left: 80px !important;
    transition: margin-left 0.3s ease-in-out;
  }
}
@media (max-width: 1040px) {
  .content .job {
    font-size: 60px;
    transition: font-size 0.3s ease-in-out;
  }
  .content {
    font-size: 60px;
    transition: font-size 0.3s ease-in-out;
  }
}
@media (max-width: 1600px) {
  .content .job {
    font-size: 55px;
    margin-bottom: 17px;
    transition: font-size 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;
  }
}
