.list_inner {
  height: 363px !important;
  width: 363px;
}

@media (min-width: 1360px) {
  .firstRow {
    display: flex;
  }
  .secondRow {
    display: flex;
  }
}

.buton {
  margin-top: 10px !important;
}
.buton a {
  color: white !important;
  font-size: 12px !important;
  padding: 0px 2px 0px 2px !important;
}
